import React from 'react';
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import ComingSoon from './components/ComingSoon';

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/comingsoon" element={<ComingSoon />} />
                <Route path="*" element={<Navigate to="/comingsoon" />} />
            </Routes>
        </Router>
    );
};

export default App;
