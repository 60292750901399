import { React, useState } from 'react';

const ComingSoon = () => {
    
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {

            setIsLoading(true);
            const response = await fetch('https://script.google.com/macros/s/AKfycbzGPeaAfEJGQHQ9RSjBQFFT5fieDZ7w2j51FLo_dVS6Owdqgk8U04BNw_fjp2_ColxY/exec', {
                method: 'POST',
                mode: 'no-cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    'email': email
                })
            });

            console.log(response);

            setStatus('success');
            setMessage('Thank you for your interest! Email successfully added.');
        } catch (error) {
            setMessage('Failed to add email.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <main className="py-5">

            <div className="container">
                <div className="h-100px d-none d-lg-block"></div>
                    <div className="row align-items-center text-center py-sm-5">
                        <div className="col-lg-8 mx-auto">
                            <h2 className="mb-2 h1">learnathing.ai</h2>
                            <p className='lead'>Fun, Creative, and Useful Learning</p>
                            <a className="btn btn-primary-soft btn-sm mb-2 fx-bold" href="#"> Coming soon! </a>
                            <div>
                                <p>Get Notified When We Launch</p>
                            </div>

                            <div>
                                <form className="row g-2 justify-content-center" onSubmit={handleSubmit}>
                                    <div className="col-md-5">
                                        <div className="input-group">
                                            <input className="form-control form-control-lg me-1 pe-5" 
                                                type="email" 
                                                placeholder="Enter your email address" 
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required />
                                        </div>
                                    </div>
                                    <div className="col-md-2 d-grid">
                                        <button className="btn btn-lg btn-primary" onClick={handleSubmit} disabled={isLoading}>
                                            {isLoading ? "Subscribing" : "Subscribe"}
                                        </button>
                                    </div>
                                </form>
                                {
                                    message ? (
                                        <div className={`alert alert-dismissible fade show mt-2 ${status === 'success' ? 'alert-success' : 'alert-danger'}`} role="alert">
                                            {message}
                                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                        </div>
                                    ) : (<></>)
                                }
                            </div>
                        </div>
                    </div> 
                <div className="h-100px d-none d-lg-block"></div>
            </div>
        </main>
    );
};

export default ComingSoon;
