import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

// Get the root element from the HTML
const rootElement = document.getElementById('root');

// Create a root using React 18's createRoot API
const root = ReactDOM.createRoot(rootElement);

// Render the App component
root.render(
  <App />
);